import { agentLoginFn } from "../../../Http/Index";

  export const loginFn=async (payload:any)=>{
     try {
       const res = await agentLoginFn(payload);
       return res.data;
     } catch (e: any) {
       // return alert(e?.response?.data?.message || "Some Technical Issue");
       throw e;
       // return e.response?.data;
     }

  }