import { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Select, VStack, HStack } from '@chakra-ui/react';
import { createAmbulanceRateCard, getRateCardByAmbulanceProviderId, updateRateCard } from '../AmbulanceProviders/Action/action';
import { ShowAlert } from '../../GlobalComponent/Alerts/Alert';
export interface AmbulanceRateCardProps {
    ambulanceProviderId: string,
    ambulanceProviderName: string,
    ambulanceType: string,
    vehicleType: string,
    rateCards: { distanceRange: string, rateType: string, prices: any }[]
}
function AmbulanceRateCard({ clearFormTwo, setClearFormTwo,providers,getAllAmbulanceProviders }:{clearFormTwo:Boolean,setClearFormTwo:any,getAllAmbulanceProviders:any,providers:any}) {
    // const [providers, setProviders] = useState([]);
    const [selectedProviderRateCard, setSelectedProviderRateCard] = useState<any>()
    const [formValues, setFormValues] = useState({
        ambulanceProviderId: '',
        ambulanceProviderName: '',
        ambulanceType: 'Select Ambulance Type', // Default type
        vehicleType: '',
    });
    const [rateCards, setRateCards] = useState([
        { distanceRange: '', rateType: 'flat', prices: null },
    ]);

    // Fetch providers from API
    // useEffect(() => {
    //     const fetchProviders = async () => {
    //         try {
    //             const response = await getAmbulanceProviders();
                
    //             setProviders(response?.data?.data);
    //         } catch (error) {
    //             console.error('Error fetching providers:', error);
    //         }
    //     };
    //     fetchProviders();
    // }, []);
    useEffect(() => {
        const fetchRateCards = async () => {
            if (formValues.ambulanceProviderId && formValues.ambulanceType) {
                try {
                    const payload = {
                        ambulanceProviderId: formValues.ambulanceProviderId
                    }
                    const ambulanceRateCards = await getRateCardByAmbulanceProviderId(payload);
                    const existingRateCards = ambulanceRateCards.find(
                        (provider: any) => provider.ambulanceType === formValues.ambulanceType
                    );
                    setSelectedProviderRateCard(existingRateCards);
                    // Set form values and rate cards based on fetched data
                    setFormValues((prevFormValues) => ({
                        ...prevFormValues,
                        vehicleType: existingRateCards?.vehicleType || '',
                    }));
                    setRateCards(existingRateCards?.rateCards || [{ distanceRange: '', rateType: 'flat', prices: '' }]);
                } catch (error) {
                    console.error('Error fetching rate cards:', error);
                }
            }
        };

        fetchRateCards();
    }, [formValues.ambulanceProviderId, formValues.ambulanceType]);

    // Handle provider selection
    const handleProviderChange = (e: any) => {
        const selectedProvider: any = providers.find((provider: any) => provider._id === e.target.value);
        setFormValues({
            ...formValues,
            ambulanceProviderId: selectedProvider?._id,
            ambulanceProviderName: selectedProvider?.name,
        });
    };

    // Handle other form inputs
    const handleInputChange = (field: any, value: any) => {
        setFormValues({ ...formValues, [field]: value });
    };

    // Handle rate card changes
    const handleRateCardChange = (index: any, field: any, value: any) => {

        if (field === "distanceRange") {
            value = `${value} km`            
        }
        const updatedRateCards: any = [...rateCards];
        updatedRateCards[index][field] = value;
        setRateCards(updatedRateCards);
    };

    // Add a new rate card
    const handleAddRateCard = () => {
        setRateCards([...rateCards, { distanceRange: '', rateType: 'flat', prices: null }]);
    };

    // Remove a rate card
    const handleRemoveRateCard = (index: number) => {
        setRateCards(rateCards.filter((_, i) => i !== index));
    };

    // Submit handler
    const handleSubmit = async () => {
        const formData = { ...formValues, rateCards };
        if (!formData.ambulanceProviderId || !formData.ambulanceProviderName) {
            return ShowAlert("warning", "Select provider");
        } else if (!formData.ambulanceType || !formData.vehicleType) {
            return ShowAlert("warning", "Select ambulance type or vehicle type");
        } else if (!formData?.rateCards?.length) {
            return ShowAlert("warning", "Add rate cards");
        }
        if (selectedProviderRateCard) {
            const res = await updateRateCard(selectedProviderRateCard._id, formData);
            if (res) {
                ShowAlert("success", "Rate card updated successfully");
                handleClearRateCard();
            }
            return;
        }
        const res = await createAmbulanceRateCard(formData);
        if (res) {
            ShowAlert("success", "Rate card added successfully");
            handleClearRateCard();

        }

    };
    const handleClearRateCard = () => {
        setFormValues({
            ambulanceProviderId: '',
            ambulanceProviderName: '',
            ambulanceType: 'Select Ambulance Type', // Default type
            vehicleType: '',
        })
        setRateCards([
            { distanceRange: '', rateType: 'flat', prices: null },]);
        // switchTab(0);
    }

    const handleInclude = (index: any, field: any) => {
        const updatedRateCards: any = [...rateCards];
        if (field === "distanceRange" && !updatedRateCards[index][field].endsWith(" km")) {
            updatedRateCards[index][field] = `${updatedRateCards[index][field]} km`;
        }
        // console.log('updatedRateCards:', updatedRateCards);
        setRateCards(updatedRateCards);

    };
    useEffect(() => {
        console.log('clearFormTwo:', clearFormTwo);
        if (clearFormTwo) {
            handleClearRateCard()
            setClearFormTwo(!clearFormTwo)
        }
        return;
        //eslint-disable-next-line
    }, [clearFormTwo])

    console.log('selectedProviderRateCard:', selectedProviderRateCard);

    return (
        <Box display={"flex"} justifyContent={"flex-start"} w={"100%"}>
            <Box p={5} maxW="50%" bg="gray.50" borderRadius="md" boxShadow="md">
                <Box mb={4}>
                    <h2>Ambulance Provider Rate Card</h2>
                </Box>
                <VStack spacing={4} align="stretch">
                    {/* Provider Dropdown */}
                    <FormControl>
                        <FormLabel>Select Provider</FormLabel>
                        <Select
                            placeholder="Select Provider"
                            onChange={handleProviderChange}
                            value={formValues.ambulanceProviderId}
                        >
                            {providers && providers?.map((provider: any) => (
                                <option key={provider._id} value={provider._id}>
                                    {provider.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    {/* Vehicle Type Dropdown */}
                    <FormControl>
                        <FormLabel>Ambulance Type</FormLabel>
                        <Select
                            placeholder="Select Ambulance Type"
                            value={formValues.ambulanceType}
                            onChange={(e) => handleInputChange('ambulanceType', e.target.value)}
                        >
                            <option value="ALS">ALS</option>
                            <option value="BLS">BLS</option>
                            <option value="ALS (with doctor/EMT)">ALS (with doctor/EMT)</option>
                            <option value="ALS (with paramedic)">ALS (with paramedic)</option>
                        </Select>
                    </FormControl>
                    {/* Vehicle Type Input */}
                    <FormControl>
                        <FormLabel>Vehicle Type</FormLabel>
                        <Input
                            type="text"
                            value={formValues.vehicleType}
                            onChange={(e) => handleInputChange('vehicleType', e.target.value)}
                            placeholder="Enter vehicle type (e.g., EECO/Bolero)"
                        />
                    </FormControl>
                    {/* Rate Cards */}
                    {rateCards.map((rateCard, index) => (
                        <Box key={index} p={4} bg="white" borderRadius="md" boxShadow="sm" mt={4}>
                            <HStack spacing={4} alignItems="flex-end">
                                <FormControl>
                                    <FormLabel>Distance Range</FormLabel>
                                    <Input
                                        type="text"
                                        value={rateCard.distanceRange.replace(" km", "")}
                                        onChange={(e) => handleRateCardChange(index, 'distanceRange', `${e.target.value}`)}
                                        onBlur={(e) => handleInclude(index, 'distanceRange')}
                                        placeholder="e.g., 0-5 km"
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Rate Type</FormLabel>
                                    <Select
                                        value={rateCard.rateType}
                                        onChange={(e) => handleRateCardChange(index, 'rateType', e.target.value)}
                                    >
                                        <option value="flat">Flat</option>
                                        <option value="per km">Per km</option>
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Prices</FormLabel>
                                    <Input
                                        type="number"
                                        value={rateCard?.prices || ""}
                                        onChange={(e) => handleRateCardChange(index, 'prices', +e.target.value)}
                                        placeholder="Enter price"
                                    />
                                </FormControl>
                                <Button
                                    colorScheme="red"
                                    width={"40%"}
                                    onClick={() => handleRemoveRateCard(index)}
                                    disabled={rateCards.length === 1}
                                >
                                    Remove
                                </Button>
                            </HStack>
                        </Box>
                    ))}
                    {/* Add Rate Card and Submit */}
                    <Button colorScheme="blue" mt={4} onClick={handleAddRateCard}>
                        Add Rate Card
                    </Button>
                    <Button colorScheme="teal" mt={4} onClick={handleSubmit}>
                        {selectedProviderRateCard ? "Update" : "Create"}
                    </Button>
                </VStack>
            </Box>
        </Box>

    );
}

export default AmbulanceRateCard;
