import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  FormErrorMessage,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
} from '@chakra-ui/react';
import { MdDelete } from "react-icons/md";
import { deleteProviderAndRateCard } from '../Action/action';

function AmbulanceServiceFormStepOne({ onNext, providers,deleteProvider,clearFormOne,setClearFormOne}: any) {
  // State variables for form fields
  const [formValues, setFormValues] = useState({
    name: '',
    addressLine1: '',
    city: '',
    state: '',
    country: '',
    pinCode: '',
    providerId:""
  });

  // State for dynamic fields//
  const [contacts, setContacts] = useState([{ countryCode: '+91', number: '' }]);
  const [emails, setEmails] = useState(['']);
  const [error, setError] = useState('');
  const [isEditFormClicked,setEditForm]=useState(false)
//clear values
const handleClear=()=>{
  setFormValues({name: '',
    addressLine1: '',
    city: '',
    state: '',
    country: '',
      providerId:"",
    pinCode: '',});
    setContacts([{ countryCode: '+91', number: '' }])
    setEmails([""])
}
  // Handle input change for standard fields
  const handleChange = (e: any) => {
    let { name, value } = e.target;
    if (name === 'name' && value) setError('');
    else if(name==="pinCode"){
     value= value.replace(/\D/g,"").slice(0,6);
    }
    setFormValues({ ...formValues, [name]: value });
   
  };

  // Handle dynamic field changes
  const handleContactChange = (index: any, field: any, value: any) => {
    const newContacts = contacts.map((contact, i) =>
      i === index ? { 
        ...contact, 
        [field]: value.replace(/\D/g, '').slice(0, 10) // Remove non-numeric characters and limit to 10 digits
      } : contact
    );
    setContacts(newContacts);
  };

  const handleEmailChange = (index: any, value: any) => {
    const newEmails = emails.map((email, i) => (i === index ? value : email));
    setEmails(newEmails);
  };

  // Add and remove contact/email handlers
  const addContact = () => setContacts([...contacts, { countryCode: '+91', number: '' }]);
  const removeContact = (index: any) => setContacts(contacts.filter((_, i) => i !== index));

  const addEmail = () => setEmails([...emails, '']);
  const removeEmail = (index: any) => setEmails(emails.filter((_, i) => i !== index));

  // Handle form submission
  const handleSubmit = (providerId:any) => {
    // console.log('providerId:', providerId);
    // e.preventDefault();
    if (!formValues.name) {
      setError('Name is required');
      return;
    }
    // Pass data back to parent or further processing

    onNext(
      { ...formValues, contacts, emails, },
      handleClear,
      isEditFormClicked ? "edit" : "create"
    );

    if(isEditFormClicked){

      setEditForm(!isEditFormClicked)
    }

  };

  const handleEdit=(providerData:any)=>{
    setEditForm(!isEditFormClicked)
    setFormValues({
      name: providerData.name || '',
      addressLine1: providerData.address?.addressLine1 || '',
      city: providerData.address?.city || '',
      state: providerData.address?.state || '',
      country: providerData.address?.country || '',
      pinCode: providerData.address?.pinCode || '',
      providerId:providerData._id
    });
  
    // Set contacts (assuming mobile is an array of objects)
    setContacts(
      providerData.contact?.mobile?.length > 0
        ? providerData.contact.mobile.map((contact:any) => ({
            countryCode: contact.countryCode || '+91',
            number: contact.number || '',
          }))
        : [{ countryCode: '+91', number: '' }]
    );
  
    // Set emails (assuming email is an array)
    setEmails(providerData.contact?.email || ['']);
  
    

  }

  const handleDelete=async(providerData:any)=>{
    //first need to get rate card id associated with provider and need to delete both provider and ambulance rate cards//
    // console.log('providerData._id:', providerData._id)
    try{

      const res:any=await deleteProviderAndRateCard(providerData._id);
      console.log('res:', res);
      deleteProvider();
    }catch(err){
      deleteProvider();
      return
    }
    // if(res?.res&&res?.rateCardRes && res?.removeProviderFromCity){
    //   ShowAlert("success","Ambulance Provider and rate card deleted successfully");
    // }else if(!res?.rateCardRes){
    //   ShowAlert("warning","rate card not deleted");
    // }else if (!res.res){
    //   ShowAlert("warning","Ambulance Provider not deleted");
    // }else if(!res.removeProviderFromCity){
    //   ShowAlert("warning","Ambulance Cities not deleted");
    // };    
  }

  useEffect(()=>{
    if(clearFormOne){
      handleClear()
      setClearFormOne(!clearFormOne)
    }
    return;
    //eslint-disable-next-line
  },[clearFormOne])

  return (
    <Box display={"flex"} justifyContent={"space-between"} w={"100%"} >

      <Box p={4} w={"50%"}   >
        <Box as="form"  p={4} borderWidth={1} borderRadius="md" mb={8}>
          <VStack spacing={4} align="stretch">
            <FormControl isInvalid={!!error} isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                placeholder="Enter service name"
              />
              {error && <FormErrorMessage>{error}</FormErrorMessage>}
            </FormControl>

            {/* Address Fields in Three Columns */}
            <Grid templateColumns="repeat(3, 1fr)" gap={4}>
              <GridItem>
                <FormControl>
                  <FormLabel>Address Line 1</FormLabel>
                  <Input
                    id="addressLine1"
                    name="addressLine1"
                    value={formValues.addressLine1}
                    onChange={handleChange}
                    placeholder="Enter address line 1"
                  />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>City</FormLabel>
                  <Input
                    id="city"
                    name="city"
                    value={formValues.city}
                    onChange={handleChange}
                    placeholder="Enter city"
                  />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>State</FormLabel>
                  <Input
                    id="state"
                    name="state"
                    value={formValues.state}
                    onChange={handleChange}
                    placeholder="Enter state"
                  />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Country</FormLabel>
                  <Input
                    id="country"
                    name="country"
                    value={formValues.country}
                    onChange={handleChange}
                    placeholder="Enter country"
                  />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Pin Code</FormLabel>
                  <Input
                    id="pinCode"
                    name="pinCode"
                    value={formValues.pinCode}
                    onChange={handleChange}
                    placeholder="Enter pin code"
                  />
                </FormControl>
              </GridItem>
            </Grid>

            {/* Dynamic Contact Fields */}
            {contacts.map((contact, index) => (
              <HStack key={index} spacing={4} alignItems={"flex-end"}>
                {/* <FormControl>
                <FormLabel>Country Code</FormLabel>
                <Input
                  value={contact.countryCode}
                  onChange={(e) => handleContactChange(index, 'countryCode', e.target.value)}
                />
              </FormControl> */}
                <FormControl>
                  <FormLabel>Contact Number</FormLabel>
                  <Input
                    value={contact.number}
                    onChange={(e) => handleContactChange(index, 'number', e.target.value)}
                    placeholder="Enter contact number"
                  />
                </FormControl>
                <IconButton
                  icon={<MdDelete />}
                  onClick={() => removeContact(index)}
                  aria-label="Remove contact"
                />
              </HStack>
            ))}
            <Button onClick={addContact} size="sm" colorScheme="teal">
              Add Contact
            </Button>

            {/* Dynamic Email Fields */}
            {emails.map((email, index) => (
              <HStack key={index} spacing={4} alignItems={"flex-end"}>
                <FormControl>
                  <FormLabel>Contact Email</FormLabel>
                  <Input
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    placeholder="Enter contact email"
                  />
                </FormControl>
                <IconButton
                  // display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  // border={"1px"}
                  // height={"60px"}
                   icon={<MdDelete />}
                  onClick={() => removeEmail(index)}
                  aria-label="Remove email"
                />
              </HStack>
            ))}
            <Button onClick={addEmail} size="sm" colorScheme="teal">
              Add Email
            </Button>

            {/* Next Button */}
            <Button onClick={handleSubmit} colorScheme="blue"  mt={4}>
             {isEditFormClicked?"Update": "Next"}
            </Button>
          </VStack>
        </Box>

        {/* Providers Table */}

      </Box>
      <Box pt={4} px={"2"} w={"50%"}>
        <TableContainer borderWidth={1} borderRadius="md">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>City</Th>
                <Th>Contact Number</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {providers?.map((provider: any) => (
                <Tr key={provider._id}>
                  <Td>{provider?.name}</Td>
                  <Td>{provider?.address?.city}</Td>
                  <Td>{provider?.contact?.mobile[0]?.number || 'N/A'}</Td>
                  <Td>
                    <Flex justifyContent="space-between">
                      <Button onClick={()=>handleEdit(provider)}>Edit</Button>
                      <Button onClick={()=>handleDelete(provider)}>Del</Button>
                    </Flex>
                  </Td>

                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
       
      </Box>
    </Box>
  );
}

export default AmbulanceServiceFormStepOne;
